import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { getSiteData } from '@/utils/siteData'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'
import { formatRange, parseStrapiDate } from '@/utils/date'
import { EventTypes } from '../constants/enums'

const EventPage = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { site, downloads, event, form } = data
  const { title, seo } = data.page || {}
  const global = {
    downloads: downloads ? downloads.nodes : [],
    form: form && form.nodes && form.nodes.length ? form.nodes[0] : {},
  }

  const startDate = parseStrapiDate(event?.startDate)
  const endDate = parseStrapiDate(event?.endDate)

  const dateRange = formatRange({
    startDate,
    endDate,
    locale: pageContext.locale,
  })

  const heroProps =
    event?.type === 'training'
      ? {
          id: 'sections.hero',
          strapi_component: 'sections.hero-compact',
          image: event?.image,
          space: 'none',
          disableTopMargin: true,
          badgeText: t('Global.training'),
          headline: event?.title,
        }
      : {
          id: 'sections.hero',
          strapi_component: 'sections.hero-release',
          disableTopMargin: true,
          badgeText: event?.place,
          headline: event?.title,
          date: dateRange,
        }

  const fallbackRedirectUri =
    event.type === EventTypes.training
      ? global.form?.pageTraining?.route
      : global.form?.pageEvent?.route
  const fallbackTerms =
    event.type === EventTypes.training
      ? {
          data: { terms: global.form?.termsTraining?.data?.termsTraining },
        }
      : {
          data: { terms: global.form?.termsEvent?.data?.termsEvent },
        }

  const eventDetailProps = {
    ...event,
    redirectUri: event.redirect?.route || fallbackRedirectUri,
    terms: event.terms || fallbackTerms,
    strapi_component: 'sections.event-detail',
    space: 'xxlarge',
    tag: 'div',
    headlineOrder: 2,
    disableTopMargin: true,
    eventDateFormatted: dateRange,
  }

  const sections = [heroProps, eventDetailProps]

  return (
    <>
      <SEO
        title={title}
        seo={seo}
        site={site.nodes[0]}
        pageContext={pageContext}
      />
      <Layout global={getSiteData(data)} pageContext={pageContext}>
        <Sections as="main" sections={sections} global={global} />
      </Layout>
    </>
  )
}

export default EventPage

export const query = graphql`
  query EventPageQuery(
    $id: String!
    $locale: String!
    $formatString: String = "DD.MM.YYYY"
  ) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    form: allStrapiForm(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SharedForm
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    event: strapiEvent(id: { eq: $id }) {
      id
      copy {
        data {
          copy
        }
      }
      dates {
        id
        endDate
        startDate
      }
      description
      endDate(formatString: $formatString, locale: $locale)
      endDateRaw: endDate
      image {
        ...SharedImage
      }
      logo {
        ...SharedImage
      }
      maxNumberPeople
      metaInformation {
        id
        title
        description
      }
      place
      price
      ranking
      startDate(formatString: $formatString, locale: $locale)
      startDateRaw: startDate
      slug
      strapi_id
      terms {
        data {
          terms
        }
      }
      teaserDescription
      teaserTitle
      title
      type
      redirect {
        route
      }
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
  }
`
